import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Everything is in flux. Modern web design has to account for a seemingly endless array of different screen sizes.
With `}<InlineCode mdxType="InlineCode">{`@media`}</InlineCode>{` queries, you can style elements based on features of the browser viewport, e.g,
the screen's width.`}</p>
    <p>{`But what if you want to create reusable components that are responsive to the container they're placed in? For example,
a news card that can span the whole width of the website or fit into a narrow column on the side. We can do that now!`}</p>
    <p>{`With `}<InlineCode mdxType="InlineCode">{`CSS Container Queries`}</InlineCode>{` we can design responsive, reusable components that adapt to the
available space. With the recent `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/110"
      }}>{`release of Firefox 110`}</a>{`,
the feature is now `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-container-queries"
      }}>{`supported by all major browsers`}</a>{`. This is a total game
changer! Let's see how it works.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABA5ws8Ej/AP/EABoQAAEFAQAAAAAAAAAAAAAAAAIAAQMREiL/2gAIAQEAAQUCF8tkkQtYSUjlUhdf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAEAABESIxMv/aAAgBAQAGPwLmVZjg/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhEUGh/9oACAEBAAE/IeggSJDJlFi1ks0a3eM9GuIf/9oADAMBAAIAAwAAABB/H//EABcRAAMBAAAAAAAAAAAAAAAAAAARITH/2gAIAQMBAT8QjuDP/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIVH/2gAIAQIBAT8QV1kfT//EAB0QAQADAAMAAwAAAAAAAAAAAAEAESExQVFhkaH/2gAIAQEAAT8QIKa5Sjd++oO1RssHZ+kolhRMLxmvPsJ0r7fKXXCuCf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Different size coffee cans stacked on top of each other.",
          "title": "Different size coffee cans stacked on top of each other.",
          "src": "/static/cb04f22af185dd58f6f2c615a52932ba/e5166/pexels-pixabay-coffee-cans.jpg",
          "srcSet": ["/static/cb04f22af185dd58f6f2c615a52932ba/f93b5/pexels-pixabay-coffee-cans.jpg 300w", "/static/cb04f22af185dd58f6f2c615a52932ba/b4294/pexels-pixabay-coffee-cans.jpg 600w", "/static/cb04f22af185dd58f6f2c615a52932ba/e5166/pexels-pixabay-coffee-cans.jpg 1200w", "/static/cb04f22af185dd58f6f2c615a52932ba/b17f8/pexels-pixabay-coffee-cans.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Pixabay / pexels.com`}</em></p>
    <h2>{`Query the Size of Containers`}</h2>
    <p>{`Container queries allow us to apply styles to elements based on the size of their container rather than the viewport.
The feature is defined in the CSS Module `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-contain-3/#container-queries"
      }}>{`CSS Containment Module Level 3`}</a>{`.
The document states:`}</p>
    <blockquote>
      <p parentName="blockquote">{`While media queries provide a method to query aspects of the user agent or device environment that a document is being
displayed in (such as viewport dimensions or user preferences), `}<strong>{`container queries`}</strong>{` allow testing aspects of
elements within the document (such as box dimensions or computed styles).`}</p>
    </blockquote>
    <p>{`We can query different size container features like `}<InlineCode mdxType="InlineCode">{`width`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`height`}</InlineCode>{`
or `}<InlineCode mdxType="InlineCode">{`inline-size`}</InlineCode>{`. The specification also includes `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-contain-3/#style-container"
      }}>{`container style queries`}</a>{`,
but those aren't supported by browsers yet.`}</p>
    <p>{`To style a component based on its parent size, we need to set containment on the parent element with
the `}<InlineCode mdxType="InlineCode">{`container-type`}</InlineCode>{` CSS property. It's recommended to also name the container
using `}<InlineCode mdxType="InlineCode">{`container-name`}</InlineCode>{`. Here's an example:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.news-card {
    container-type: inline-size;
    container-name: news-card;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`You can also set the type and name simultaneously with the `}<InlineCode mdxType="InlineCode">{`container`}</InlineCode>{` shorthand property:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.news-card {
    container: news-card / inline-size;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Now we can query the container with the `}<InlineCode mdxType="InlineCode">{`@container`}</InlineCode>{` CSS at-rule and apply some conditional
styling. For example, if the container's width is below a certain threshold:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@container news-card (max-width: 25rem) {
    .card-image {
        flex-basis: 100%;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Demo: Responsive News Card`}</h2>
    <p>{`I've created a simple `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/abaOdLw"
      }}>{`demo on Codepen`}</a>{` that shows a news card component.
If enough space is available, the card lays out its content horizontally. If the container's width falls below a certain
threshold, the text is moved below the image.`}</p>
    <iframe scrolling="no" title="Code example for CSS Container Queries" src="https://codepen.io/alexlehner86/embed/abaOdLw?default-tab=result" loading="lazy" allowtransparency="true">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/abaOdLw">
    CSS Container Queries Demo</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`Further Reading`}</h2>
    <p>{`There's a lot more to learn about container queries. Check out the following guides and examples:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ishadeed.com/article/container-queries-are-finally-here/"
        }}>{`“CSS container queries are finally here”, by Ahmad Shadeed`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/early-days-of-container-style-queries/"
        }}>{`“Early Days of Container Style Queries”, by Geoff Graham`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.oddbird.net/2022/08/18/cq-syntax/"
        }}>{`“Use the Right Container Query Syntax”, by Miriam Suzanne`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.matuzo.at/blog/2022/100daysof-day56/"
        }}>{`“Day 56: container queries”, by Manuel Matuzović`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/cq-stable/"
        }}>{`“Container queries land in stable browsers”, by Una Kravets`}</a></li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`I'm super excited about CSS container queries! Yet, I've barely scratched the surface of all the possible use cases.
My goal is to use the feature in more and more projects and contiuously improve my understanding of it.`}</p>
    <p>{`In my opinion, one thing is certain: Container queries will change the way we write CSS for good. What ideas and
use cases have you already implemented with the feature?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      